<template>
    <div>
        <tab name="3"></tab>
        <el-alert :show-icon="true" title="按住鼠标，可拖动排序" :closable="false"></el-alert>
        <div class="page-group-box MT10" v-loading="listLoading">
            <el-row v-if="dataList" :gutter="10">
                <vuedraggable v-model="dataList" draggable=".img" @change="updateSort">
                    <el-col v-for="(item,index) in dataList" :key="index" class="img">
                        <el-image :src="item.path | coverImage(400,400)" fit="cover" lazy :preview-src-list="dataList.map(v => v.path)">
                            <div slot="error" class="image-slot">
                                <i class="iconfont">&#xe639;</i>
                            </div>
                        </el-image>
                        <div class="tool">
                            <el-button icon="el-icon-delete" type="text" @click="del(item.id)" size="mini"></el-button>
                        </div>
                    </el-col>
                </vuedraggable>
            </el-row>
        </div>
        <div class="page-group-box MT10">
            <el-button  type="primary" size="small" @click="drawerShow=true">新增图片</el-button>
        </div>

        <el-drawer
            title="上传图片"
            :visible.sync="drawerShow"
            :wrapperClosable="false"
            :destroy-on-close="true"
            size="auto"
            @close="close"
        >
            <div class="drawer-content">
                <image-upload 
                    :url.sync="path" 
                    :urlFull.sync="path" 
                >
                </image-upload>
            </div>
            <div class="drawer-footer">
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="addImg()" :loading="btnLoading"
                    >提交</el-button
                >
            </div>
        </el-drawer>

    </div>
</template>

<script>
import Tab from './components/tab'
import vuedraggable from 'vuedraggable';
import ImageUpload from '@/components/ImageUpload';


export default {
    components:{Tab,vuedraggable,ImageUpload},
    data(){
        return{
            dataList: null,
            listLoading: true,
            drawerShow:false,
            btnLoading:false,
            path:'',
            ids:[]
        }
    },
    mounted(){
        this.getDataList();
    },
    methods:{
        //获取列表
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/userCommon/media",
                method: "POST",
                data: {
                    id:this.id
                },
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.dataList = result;
                    } else {
                        this.$message.error(msg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //删除图片
        del(id){
            this.$confirm('删除后无法恢复，是否确认删除？', '提示', {
                type: 'warning',
                center: true,
                closeOnClickModal: false
            }).then(() => {
                this.$request({
                    url: '/userCommon/deleteMedia',
                    method: "POST",
                    params: {
                        id:id
                    },
                })
                    .then((res) => {
                        const { state, msg } = res.data;
                        if (state == 1) {
                            this.getDataList();
                        } else {
                            this.$message.error(msg || "请求失败");
                        }
                    })
                    .catch(() => {
                        this.$message.error("请求错误");
                    })
                    .finally(() => {
                        // this.listLoading = false;
                    });
            }).catch(() => {});
        },
        //排序
        updateSort(){
            this.ids = this.dataList.map( v => v.id )
            // this.btnLoading = true;
            this.$request({
                url: '/userCommon/mediaSort',
                method: "POST",
                data: {
                    ids:this.ids,
                },
            })
                .then((res) => {
                    const { state, msg } = res.data;
                    if (state == 1) {
                        this.$message.success("设置成功");
                    } else {
                        this.$message.error(msg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    // this.btnLoading = false;
                });
        },
        //关闭抽屉
        close(){
            this.path = '';
            this.drawerShow = false;
        },   
        //新增图片；
        addImg(){
            if( this.path ){
                this.btnLoading = true;
                this.$request({
                    url: '/userCommon/addMedia',
                    method: "POST",
                    data: {
                        path:this.path,
                    },
                })
                    .then((res) => {
                        const { state, msg, result } = res.data;
                        if (state == 1 && result) {
                            this.getDataList();
                            this.close();
                        } else {
                            this.$message.error(msg || "请求失败");
                        }
                    })
                    .catch(() => {
                        this.$message.error("请求错误");
                    })
                    .finally(() => {
                        this.btnLoading = false;
                    });
            }else{
                return this.$message.error('请上传图片');
            }
        },   
    }
}
</script>

<style lang="scss" scoped>
.img{
    border:1px $--border-color-base solid;
    padding: 5px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 160px;
    position: relative;
    background: #ffffff;
    margin: 5px;
    .el-image{
        width: 100%;
        height: 100%;
    }
    .tool{
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        background: #ffffff;
        height: 26px;
        width: 26px;
        text-align: center;
        border-radius: 4px;
        .el-button{
            height: 26px;
        }
    }
    &:hover{
        .tool{
            display: block;
        }
    }
}

</style>